<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table dense :headers="headers" :items="desserts" :loading="loading" hide-default-footer :page.sync="page"
          :items-per-page="table.itemsPerPage" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Jurnal Umum</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field label="Tanggal Awal *" type="date" v-model="filter.tanggal_mulai" outlined hide-details
                @keydown.enter="optionChange()" />
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field label="Tanggal Akhir *" type="date" v-model="filter.tanggal_akhir" outlined hide-details
                @keydown.enter="optionChange()" />
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="table.search" append-icon="mdi-magnify" label="no transaksi / keterangan" single-line
                outlined hide-details dense @keydown.enter="optionChange()"></v-text-field>
              <v-btn class="secondary" @click="optionChange()"> Cari </v-btn>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="1024px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialog()">
                    Tambah
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field label="Tanggal *" type="date" outlined hide-details
                            v-model="editedItem.tanggal" />
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field v-if="editedItem.keterangan_edit == true" v-model="editedItem.keterangan"
                            label="Keterangan *" outlined hide-details></v-text-field>
                          <v-text-field v-else readonly v-model="editedItem.keterangan"
                            label="Keterangan *"></v-text-field>
                        </v-col>
                        <v-btn color="primary" v-if="editedItem.btn_tambah == true" small
                          @click="addBody()">Tambah</v-btn>
                        <v-col cols="12" sm="12" md="12">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Akun</th>
                                  <th class="text-left">Debit</th>
                                  <th class="text-left">Kredit</th>
                                  <th class="text-left">Aksi</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(m, index) in editedItem.body" :key="index">
                                  <td>
                                    <v-autocomplete v-model="editedItem.body[index].akunSelected" hide-details
                                      :items="akuns" dense item-text="text" item-value="value" outlined></v-autocomplete>
                                  </td>
                                  <td>
                                    <v-text-field hide-details v-model="editedItem.body[index].debit"></v-text-field>
                                    <div style="font-weight: bold; color: red">
                                      {{ new Intl.NumberFormat(['id']).format(editedItem.body[index].debit) }}
                                    </div>
                                  </td>
                                  <td>
                                    <v-text-field hide-details v-model="editedItem.body[index].kredit"></v-text-field>
                                    <div style="font-weight: bold; color: red">
                                      {{ new Intl.NumberFormat(['id']).format(editedItem.body[index].kredit) }}
                                    </div>
                                  </td>
                                  <td>
                                    <v-icon v-if="editedItem.body[index].delete == true" small class="mr-2"
                                      @click="deleteSubItem(index)">
                                      {{ icons.mdiDelete }}
                                    </v-icon>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="save"> Simpan </v-btn>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Apakah yakin akan dihapus?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="closeDelete">Batal</v-btn>
                    <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </v-toolbar>
          </template>
          <template v-slot:item="{ item, index }">
            <tr>
              <td>
                <span v-if="index === 0">
                  {{ formatDate(item.tanggal) }}
                </span>
                <span v-else>
                  <span v-if="desserts[index].tanggal === desserts[index - 1].tanggal"></span>
                  <span v-else>{{ formatDate(item.tanggal) }}</span>
                </span>
              </td>
              <td>
                <span v-if="index === 0">
                  {{ item.id_header }}
                </span>
                <span v-else>
                  <span v-if="desserts[index].id_header === desserts[index - 1].id_header"></span>
                  <span v-else>{{ item.id_header }}</span>
                </span>
              </td>
              <td>
                <span v-if="index === 0">
                  <span v-if="desserts[index].id_penyesuaian_header == null">
                    {{ item.keterangan }}
                  </span>
                  <span v-else>{{ item.keterangan + '. Penyesuaian:' + item.id_penyesuaian_header }}</span>
                </span>
                <span v-else>
                  <span v-if="desserts[index].id_header === desserts[index - 1].id_header &&
                    desserts[index].keterangan === desserts[index - 1].keterangan
                    "></span>
                  <span v-else>
                    <span v-if="desserts[index].id_penyesuaian_header == null">
                      {{ item.keterangan }}
                    </span>
                    <span v-else>{{ item.keterangan + '. Penyesuaian:' + item.id_penyesuaian_header }}</span>
                  </span>
                </span>
              </td>

              <td>{{ item.nomor + ' ' + item.nama }}</td>
              <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.debit) }}</td>
              <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.kredit) }}</td>
              <td>
                <span v-if="index === 0">
                  <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon>
                  <!-- <v-icon small class="mr-2" @click="deleteItem(item)">
                    {{ icons.mdiDelete }}
                  </v-icon> -->
                </span>
                <span v-else>
                  <span v-if="desserts[index].id_header === desserts[index - 1].id_header &&
                    desserts[index].keterangan === desserts[index - 1].keterangan
                    "></span>
                  <span v-else>
                    <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon>
                    <!-- <v-icon small class="mr-2" @click="deleteItem(item)">
                      {{ icons.mdiDelete }}
                    </v-icon> -->
                  </span>
                </span>
              </td>
            </tr>
            <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" small @click="getDataFromApi"> Reload</v-btn>
          </template>
        </v-data-table>

        <div class="text-center pl-4 pt-4 pb-4">
          <v-row>
            <v-col cols="3">
              <v-select label="Jumlah Per Halaman" :items="length_menus" hide-details="auto" outlined dense
                v-model="table.itemsPerPage" @change="optionChange()"></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="page" :length="table.pageCount" :total-visible="7"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
table tbody tr td,
table thead tr th {
  border: 1px solid black;
}
</style>

<script>
import axios from 'axios'
import { mdiPencil, mdiDelete } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    filter: {
      tanggal_mulai: new Date().toJSON().slice(0, 8).replace(/-/g, '-') + '01',
      tanggal_akhir: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Tanggal', value: 'tanggal', sortable: false },
      { text: 'No Transaksi', value: 'id_header', sortable: false },
      { text: 'Keterangan', value: 'keterangan', sortable: false },
      { text: 'Akun', value: 'akun', sortable: false },
      { text: 'Debit', value: 'debit', sortable: false, align: 'right' },
      { text: 'Kredit', value: 'kredit', sortable: false, align: 'right' },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    desserts: [],
    loading: true,
    page: 1,
    table: {
      pageCount: 0,
      itemsPerPage: 25,
      search: '',
    },
    length_menus: [25, 50, 100, 500, 1000],

    editedIndex: -1,
    editedItem: {
      tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      keterangan: '',
      keterangan_edit: true,
      btn_tambah: true,
      body: [
        {
          akunSelected: '1',
          debit: 0,
          kredit: 0,
          delete: false,
        },
        {
          akunSelected: '1',
          debit: 0,
          kredit: 0,
          delete: false,
        },
      ],
    },
    defaultItem: {
      tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      keterangan: '',
      keterangan_edit: true,
      btn_tambah: true,
      body: [
        {
          akunSelected: '1',
          debit: 0,
          kredit: 0,
          delete: false,
        },
        {
          akunSelected: '1',
          debit: 0,
          kredit: 0,
          delete: false,
        },
      ],
    },
    akuns: [],
    search: '',
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Jurnal Umum' : 'Penyesuaian Jurnal Umum'
    },
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    page: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  created() {
    this.getAkunsFromApi()
    this.getDataFromApi()
  },

  methods: {
    getAkunsFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Akun/getAll`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // console.log(response.data.data)
            response.data.data.forEach(element => {
              this.akuns.push({
                text: element.nomor + ' ' + element.nama,
                value: element.id,
              })
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataFromApi() {
      this.loading = true

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.page)
      form.append('itemsPerPage', this.table.itemsPerPage)
      form.append('search', this.table.search)
      form.append('tanggal_mulai', this.filter.tanggal_mulai)
      form.append('tanggal_akhir', this.filter.tanggal_akhir)
      axios
        .post(`${apiRoot}/api/Jurnal/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.table.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    getDataEdit() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_header', this.editedItem.id_header)
      axios
        .post(`${apiRoot}/api/Jurnal/getByHeader`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // this.desserts = response.data.data
            // console.log(response.data.data)
            this.editedItem.body = []
            response.data.data.forEach(element => {
              this.editedItem.body.push({
                akunSelected: element.id_akun,
                debit: element.debit,
                kredit: element.kredit,
                delete: false,
              })
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.editedItem.tanggal = Object.assign({}, item)
      this.editedItem.id_header = item.id_header
      this.editedItem.tanggal = new Date().toJSON().slice(0, 10).replace(/-/g, '-')
      this.editedItem.keterangan = item.keterangan
      // this.editedItem.keterangan = 'Penyesuaian Jurnal No Transaksi:' + item.id_header
      this.editedItem.keterangan_edit = false
      this.editedItem.btn_tambah = false
      this.getDataEdit()
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      // this.$nextTick(() => {
      // })
    },
    openDialog() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id_header', this.editedItem.id_header)
        form.append('tanggal', this.editedItem.tanggal)
        form.append('keterangan', this.editedItem.keterangan)
        this.editedItem.body.forEach((element, index) => {
          form.append('body[' + index + '][id_akun]', element.akunSelected)
          form.append('body[' + index + '][debit]', element.debit)
          form.append('body[' + index + '][kredit]', element.kredit)
        })
        axios
          .post(`${apiRoot}/api/Jurnal/update`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              // this.search = ''
              // this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('tanggal', this.editedItem.tanggal)
        form.append('keterangan', this.editedItem.keterangan)
        this.editedItem.body.forEach((element, index) => {
          form.append('body[' + index + '][id_akun]', element.akunSelected)
          form.append('body[' + index + '][debit]', element.debit)
          form.append('body[' + index + '][kredit]', element.kredit)
        })
        axios
          .post(`${apiRoot}/api/Jurnal/add`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              // this.search = ''
              // this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    addBody() {
      this.editedItem.body.push({
        akunSelected: { text: '', value: '' },
        debit: 0,
        kredit: 0,
        delete: true,
      })
    },
    deleteSubItem(index) {
      this.editedItem.body.splice(index, 1)
    },
    optionChange() {
      this.page = 1
      this.getDataFromApi()
    },
  },
}
</script>
